<div class="px-3">
  <h1 class="color-black op-09 fs16">New Location</h1>

  <p class="color-black op-05 fs14 mb-0">Complete this form and select 'New Location' to add a new location.</p>
  <form class="modal-form mt-3" [formGroup]="clientForm" (ngSubmit)="createClient()">
    <div class="form-group">
      <label [ngClass]="{ 'text-danger': isSubmitted && f.name.errors }" for="username">Name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Enter Company Name"
        formControlName="name"
        [ngClass]="{ 'invalid-field': isSubmitted && f.name.errors }"
        id="username"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
      />
    </div>
    <div class="form-group">
      <label>Contact Person</label>
      <input
        type="text"
        class="form-control"
        placeholder="Enter your Contact Person"
        formControlName="contact_person"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
      />
    </div>
    <div class="form-row">
      <div class="col-sm-6">
        <label for="mobile">Mobile Number </label>
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="btn-group about-more number-flag" dropdown>
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle border bg-transparent fs14 text-dark"
                aria-controls="dropdown-basic"
              >
                <img src="assets/images/country-flags/{{ mcountryFlag }}.png" alt="country" /> {{ mcountryCode }}
                <em class="fas fa-caret-down ml-1 op-05"></em>
              </button>
              <ul
                id="dropdown-basic"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-basic"
                formControlName="mobile_code"
              >
                <li role="menuitem" *ngFor="let code of phoneCode">
                  <span (click)="setCountryInfo(code, 'mobile')">
                    <img src="assets/images/country-flags/{{ code.countries_iso_code }}.png" alt="country" />
                    {{ code.countries_isd_code }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="00000 00000"
            formControlName="mobile"
            id="mobile"
            (keypress)="isNumberKey($event.keyCode, $event.target.value)"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <label for="mobile">Phone Number</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="btn-group about-more number-flag" dropdown>
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle border bg-transparent fs14 text-dark"
                aria-controls="dropdown-basic"
              >
                <img src="assets/images/country-flags/{{ pcountryFlag }}.png" alt="country" /> {{ pcountryCode }}
                <em class="fas fa-caret-down ml-1 op-05"></em>
              </button>
              <ul
                id="dropdown-basic"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-basic"
                formControlName="phone_code"
              >
                <li role="menuitem" *ngFor="let code of phoneCode">
                  <span (click)="setCountryInfo(code, 'phone')">
                    <img src="assets/images/country-flags/{{ code.countries_iso_code }}.png" alt="country" />
                    {{ code.countries_isd_code }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="00000 00000"
            formControlName="phone"
            (keypress)="isNumberKey($event.keyCode, $event.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label [ngClass]="{ 'text-danger': isSubmitted && f.email.errors }" for="email">Email</label>
      <input
        type="email"
        class="form-control"
        placeholder="abcd@example.com"
        formControlName="email"
        [ngClass]="{ 'invalid-field': (isSubmitted && f.email.errors) || isEmail }"
        id="email"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
      />
      <div *ngIf="f.email.errors" class="text-danger mt-1">
        <p class="mb-0" *ngIf="f.email.errors.email">Please Enter Valid Email</p>
      </div>
      <div *ngIf="isEmail" class="text-danger mt-1">
        <p class="mb-0" *ngIf="isEmail">E-Mail Id already exists</p>
      </div>
    </div>
    <div class="form-group">
      <label>Background Color</label>
      <ul class="list-group list-group-horizontal flex-wrap flex-md-nowrap roaster-bg-group mb-4">
        <li class="list-group-item mb-4 mb-md-0" *ngFor="let color of colorCodes">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              class="custom-control-input"
              id="color{{ color.id }}"
              [value]="color.color"
              formControlName="background_color"
              name="background_color"
            />
            <label
              class="custom-control-label font-weight-bold roaster-color{{ color.id }}"
              for="color{{ color.id }}"
            ></label>
          </div>
        </li>
      </ul>
    </div>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="is_newsletter_subscribed"
        id="is_newsletter_subscribed"
      />
      <label class="custom-control-label font-weight-bold" for="is_newsletter_subscribed"
        >Subscribe R101 Newsletter</label
      >
    </div>
    <div class="mb-4 mt-3 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary btn-cancel border-0 fs14 mr-3"
        tooltip="Cancel"
        (click)="closeModel()"
      >
        Cancel
      </button>
      <button
        type="submit"
        [disabled]="loading"
        class="btn btn-secondary btn-create border-0 fs14"
        tooltip="New Location"
        *ngIf="!isEdit"
      >
        New Location
      </button>
      <button
        type="submit"
        [disabled]="loading"
        class="btn btn-secondary btn-create border-0 fs14"
        tooltip="Update Location"
        *ngIf="isEdit"
      >
        Update Location
      </button>
    </div>
  </form>
</div>
