import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { GeolocationService } from '../services';

@Component({
  selector: 'app-customer-creation',
  templateUrl: './customer-creation.component.html',
})
export class CustomerCreationComponent implements OnInit {
  customercreationform: FormGroup;
  submitted = false;
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  message: string;
  message2: string;
  message3: string;
  isEdit = false;
  phoneCode: any = [];
  editdetailsarray = [];
  countryCode;
  nzcode;
  countryFlag;
  defaultCountryCode: any = 'NZ';
  timeformatvalue = '';
  dateformatvalue = '';
  domainInfo: any = {};

  constructor(
    private geolocationService: GeolocationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.getCurrentLocation();
    this.getPhoneCode();
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 11) {
        this.timeformatpopup();
      }
      if (data === 12) {
        // this.search = '';
        this.timezonepopup();
      }
      if (data === 13) {
        // this.search = '';
        this.dateformatpopup();
      }
    });
    this.customercreationform = this.formBuilder.group({
      Name: ['', Validators.required],
      CompanyName: ['', Validators.required],
      CompanyWebsite: ['', Validators.required],
      countryCode: ['', Validators.required],
      MobileNumber: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Domain: ['', Validators.required],
      TimeFormat: ['', Validators.required],
      TimeZone: ['', Validators.required],
      DateFormat: ['', Validators.required],
    });
    const a = JSON.parse(localStorage.getItem('Editarray'));
    if (a == null) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
    if (this.isEdit) {
      this.editdetailsarray = a;
      this.editdetailspopulate();
    }
    // alert("2")
  }
  async getCurrentLocation() {
    this.defaultCountryCode = await this.geolocationService.getCurrentCountry();
    this.setcountryFlag();
  }
  setCountryInfo(code) {
    this.setcountrycodeflag(code);
  }

  getPhoneCode() {
    this.apiService.get('phone-code').subscribe((phoneCode: any) => {
      if (phoneCode.status === 200) {
        this.phoneCode = phoneCode['phonecodes'];
        if (this.phoneCode && this.phoneCode.length > 0) {
          this.setcountryFlag();
        }
      }
    });
  }
  setcountryFlag() {
    const intphoneData = this.phoneCode.filter((obj) => obj.countries_iso_code === this.defaultCountryCode);
    if (!this.isEdit) {
      if (intphoneData && intphoneData.length > 0) {
        this.setcountrycodeflag(intphoneData[0]);
      }
    } else {
      if (this.isEdit) {
        const phoneData = this.phoneCode.filter((obj) => obj.id === this.editdetailsarray['countryCode']);
        if (phoneData && phoneData.length > 0) {
          this.setcountrycodeflag(phoneData[0]);
        } else {
          this.setcountrycodeflag(intphoneData[0]);
        }
      }
    }
  }
  setcountrycodeflag(intphoneData) {
    this.countryCode = intphoneData.countries_isd_code;
    this.countryFlag = intphoneData.countries_iso_code;
    this.nzcode = intphoneData.id;

    this.customercreationform.patchValue({
      countryCode: this.nzcode,
    });
  }
  editdetailspopulate() {
    this.timeformatvalue = this.editdetailsarray['timeformat'];
    this.dateformatvalue = this.editdetailsarray['dateformat'];
    let timeformat = '';
    let dateformat = '';
    if (this.timeformatvalue == 'hh:mm @') {
      timeformat = moment().format('hh:mm A');
    }
    if (this.timeformatvalue == 'hh:mm:ss @') {
      timeformat = moment().format('hh:mm:ss A');
    }
    if (this.timeformatvalue == 'HH:mm') {
      timeformat = moment().format('HH:mm');
    }
    if (this.timeformatvalue == 'HH:mm:ss') {
      timeformat = moment().format('HH:mm:ss');
    }
    // dateformat
    if (this.dateformatvalue == 'dd/MM/yyyy') {
      dateformat = moment().format('DD/MM/YYYY');
    }
    if (this.dateformatvalue == 'MM/dd/yyyy') {
      dateformat = moment().format('MM/DD/YYYY');
    }
    if (this.dateformatvalue == 'yyyy/MM/dd') {
      dateformat = moment().format('YYYY/MM/DD');
    }
    if (this.dateformatvalue == 'MMM dd,yyyy') {
      dateformat = moment().format('MMMM DD, YYYY');
    }
    if (this.dateformatvalue == 'dd MMM, yyyy') {
      dateformat = moment().format('DD MMMM, YYYY');
    }
    // var dateformat =moment(this.editdetailsarray['dateformat']).format("YYYY-MM-DD")
    // let countrycodeflag = true;
    // if (this.editdetailsarray['countryCode'] === null) {
    //   countrycodeflag = false;
    // } else {
    //   countrycodeflag = true;
    // }
    this.customercreationform.patchValue({
      Name: this.editdetailsarray['name'],
      CompanyName: this.editdetailsarray['clientname'],
      CompanyWebsite: this.editdetailsarray['companyWebsite'],
      MobileNumber: this.editdetailsarray['mobileNumber'],

      Email: this.editdetailsarray['email'],
      Domain: this.editdetailsarray['subdomain'],
      TimeFormat: timeformat,
      TimeZone: this.editdetailsarray['timezone'],
      DateFormat: dateformat,
    });
  }
  isNumberKey(evt, value) {
    //if (evt != 43 && evt > 31 && (evt < 48 || evt > 57)){
    if (evt > 31 && (evt < 48 || evt > 57)) {
      return false;
    } else if (value.length > 14) {
      return false;
    }
    return true;
  }
  receivetimeformat($event) {
    this.message = $event;
    this.customercreationform.controls['TimeFormat'].setValue(this.message);
  }
  receivetime($event) {
    this.timeformatvalue = $event;
  }
  receivedate($event) {
    this.dateformatvalue = $event;
  }
  receivedateformat($event) {
    this.message2 = $event;
    this.customercreationform.controls['DateFormat'].setValue(this.message2);
  }
  receivetimezonevalue($event) {
    this.message3 = $event;
    this.customercreationform.controls['TimeZone'].setValue(this.message3);
  }
  get f() {
    return this.customercreationform.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customercreationform.invalid) {
      return;
    }
    const object = {};
    object['name'] = this.customercreationform.get('Name').value;
    object['clientname'] = this.customercreationform.get('CompanyName').value;
    object['companyWebsite'] = this.customercreationform.get('CompanyWebsite').value;
    object['mobileNumber'] = this.customercreationform.get('MobileNumber').value;
    object['countryCode'] = this.customercreationform.get('countryCode').value;
    object['email'] = this.customercreationform.get('Email').value;
    object['domain'] = this.customercreationform.get('Domain').value;
    object['timeFormat'] = this.timeformatvalue;
    object['dateFormat'] = this.dateformatvalue;
    object['timezone'] = this.customercreationform.get('TimeZone').value;
    if (this.isEdit) {
      this.apiService.put('domain/update-domain/' + this.editdetailsarray['id'], object).subscribe((client: any) => {
        this.successMessage(client);
      });
    } else {
      this.apiService.post('domain/create-domain', object).subscribe(
        (client: any) => {
          this.successMessage(client);
        },
        (err) => {
          if (err.error.message) {
            this.toastr.error(err.error.message);
          }
        },
      );
    }

    this.router.navigate(['/client-information']);
  }
  successMessage(client) {
    if (client.status === 200) {
      this.toastr.success(client.message);
    }
  }
  closeModel() {
    this.router.navigate(['/client-information']);
  }

  timeformatModal(template: TemplateRef<any>) {
    this.modalRef1 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }
  timezoneModal(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }
  dateformatModal(template: TemplateRef<any>) {
    this.modalRef3 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }
  timeformatpopup() {
    this.modalRef1.hide();
    // this.apiService.searchValue.emit({id:2,value:this.search, showHidden: this.showHidden});
  }
  timezonepopup() {
    this.modalRef2.hide();
    // this.apiService.searchValue.emit({id:2,value:this.search, showHidden: this.showHidden});
  }
  dateformatpopup() {
    this.modalRef3.hide();
    // this.apiService.searchValue.emit({id:2,value:this.search, showHidden: this.showHidden});
  }
}
