<div class="px-3 py-3">
  <form [formGroup]="customercreationform" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Name</label>
          <input
            type="text"
            formControlName="Name"
            [readonly]="isreadonlyenabled"
            class="form-control customercreateform"
            placeholder="Enter Name"
            [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
          />
          <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
            <div *ngIf="f.Name.errors.required">Name is required</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Staff Count</label>
          <input
            type="text"
            formControlName="staff_count"
            [readonly]="isreadonlyenabled"
            class="form-control customercreateform"
            placeholder="Enter Staff Count"
            [ngClass]="{ 'is-invalid': submitted && f.staff_count.errors }"
          />
          <div *ngIf="submitted && f.staff_count.errors" class="invalid-feedback">
            <div *ngIf="f.staff_count.errors.required">Staff Count is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Company Name</label>
          <input
            type="text"
            formControlName="CompanyName"
            [readonly]="isreadonlyenabled"
            class="form-control customercreateform"
            placeholder="Enter Company Name"
            [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }"
          />
          <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
            <div *ngIf="f.CompanyName.errors.required">CompanyName is required</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Company Website</label>
          <input
            type="text"
            formControlName="CompanyWebsite"
            [readonly]="isreadonlyenabled"
            class="form-control customercreateform"
            placeholder="Enter Company Website"
            [ngClass]="{ 'is-invalid': submitted && f.CompanyWebsite.errors }"
          />
          <div *ngIf="submitted && f.CompanyWebsite.errors" class="invalid-feedback">
            <div *ngIf="f.CompanyWebsite.errors.required">CompanyWebsite is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="fs16 color-black">Mobile Number</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="btn-group about-more number-flag" dropdown>
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary custombtninputgrp dropdown-toggle border bg-transparent fs14 text-dark"
                aria-controls="dropdown-basic"
              >
                <img src="assets/images/country-flags/{{ countryFlag }}.png" alt="country" /> {{ countryCode }}
                <em class="fas fa-caret-down ml-1 op-05"></em>
              </button>
            </div>
          </div>

          <input
            type="number"
            (keypress)="isNumberKey($event.keyCode, $event.target.value)"
            formControlName="MobileNumber"
            class="form-control custominputgroup customercreateform"
            placeholder="Mobile Number"
            [ngClass]="{ 'is-invalid': submitted && f.MobileNumber.errors }"
            [readonly]="isreadonlyenabled"
          />
          <div *ngIf="submitted && f.MobileNumber.errors" class="invalid-feedback">
            <div *ngIf="f.MobileNumber.errors.required">MobileNumber is required</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Email</label>
          <input
            type="text"
            formControlName="Email"
            [readonly]="isreadonlyenabled"
            class="form-control customercreateform"
            placeholder="Enter Email"
            [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
          />
          <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
            <div *ngIf="f.Email.errors.required">Email is required</div>
            <div *ngIf="f.Email.errors.email">Email is not valid</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Domain</label>
          <input
            type="text"
            formControlName="Domain"
            [readonly]="isreadonlyenabled"
            class="form-control customercreateform"
            placeholder="Enter Domain"
            [ngClass]="{ 'is-invalid': submitted && f.Domain.errors }"
          />
          <div *ngIf="submitted && f.Domain.errors" class="invalid-feedback">
            <div *ngIf="f.Domain.errors.required">Domain is required</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Time Format</label>
          <input
            type="text"
            [readonly]="isreadonlyenabled"
            formControlName="TimeFormat"
            class="form-control customercreateform"
            placeholder="Enter Time Format"
            [ngClass]="{ 'is-invalid': submitted && f.TimeFormat.errors }"
          />
          <div *ngIf="submitted && f.TimeFormat.errors" class="invalid-feedback">
            <div *ngIf="f.TimeFormat.errors.required">TimeFormat is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Time Zone</label>
          <input
            type="text"
            [readonly]="isreadonlyenabled"
            formControlName="TimeZone"
            class="form-control customercreateform"
            placeholder="Enter Time Zone"
            [ngClass]="{ 'is-invalid': submitted && f.TimeZone.errors }"
          />
          <div *ngIf="submitted && f.TimeZone.errors" class="invalid-feedback">
            <div *ngIf="f.TimeZone.errors.required">TimeZone is required</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group mb-4">
          <label class="fs16 color-black">Date Format</label>
          <input
            type="text"
            [readonly]="isreadonlyenabled"
            formControlName="DateFormat"
            class="form-control customercreateform"
            placeholder="Enter Date Format"
            [ngClass]="{ 'is-invalid': submitted && f.DateFormat.errors }"
          />
          <div *ngIf="submitted && f.DateFormat.errors" class="invalid-feedback">
            <div *ngIf="f.DateFormat.errors.required">DateFormat is required</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
