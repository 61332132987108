<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<div class="container">
  <div class="row align-items-center">
    <div class="col-xl-5 col-md-8 offset-md-2 col-lg-6 offset-lg-3 offset-xl-4 my-auto">
      <div class="d-flex align-items-center justify-content-center h-100vh">
        <div class="card auth-card col-md-11">
          <div class="card-header text-center text-white auth-header">
            <img src="assets/images/logo-w.png" alt="logo" class="logo" />
          </div>
          <div class="card-body bg-white">
            <h1 class="card-title text-dark text-left fs18">FORGOT PASSWORD</h1>
            <div class="border-primary w-25 login-bdr mb-3"></div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
              <div class="form-group bg-white">
                <label for="usertype" class="fs14 text-dark font-weight-bold">User Type</label>
                <select class="custom-select fs14" formControlName="usertype">
                  <option value="1">Client</option>
                  <option value="2">Staff</option>
                </select>
              </div>
              <div class="form-group bg-white">
                <label for="exampleInputPassword1" class="fs14 text-dark font-weight-bold">E-Mail</label>
                <input
                  type="text"
                  class="form-control bg-white fs14"
                  id="exampleInputPassword1"
                  placeholder="email"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && email.errors }"
                  required
                />
                <div *ngIf="submitted && email.errors" class="invalid-feedback my-2">
                  <div *ngIf="email.errors.required">Please enter Email</div>
                  <div *ngIf="email.errors.email">Please enter valid address</div>
                </div>
              </div>
              <div class="text-right float-right">
                <p class="text-primary c-pointer" routerLink="/login">Back to Login</p>
              </div>
              <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
