import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { ApiService, S3UploadService } from '../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { humanizeBytes } from 'ngx-uploader';
import { environment } from '../../environments/environment';
import { passwordRegEx } from '../constants/defaultValues';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(s3upload, apiService, toastr, formBuilder, router) {
        this.s3upload = s3upload;
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.router = router;
        this.userType = localStorage.getItem('userType');
        this.userId = localStorage.getItem('userid');
        this.isSubmitted = false;
        this.loading = false;
        this.fileUrl = environment.fileUrl;
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.supportFormat = 'png,jpg,gif,jpeg';
        this.uploadfileList = [];
        this.progress = [{ progress: 0 }];
        this.removeFileIds = [];
        this.enableChange = false;
        this.isPass = false;
        this.isCurrent = false;
        this.isDisable = false;
        this.profileImage = '';
        this.passwordRegEx = passwordRegEx;
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
    }
    ProfileComponent.prototype.ngOnInit = function () {
        if (this.userType === '2') {
            this.getProfile(this.userId);
            this.createForm();
        }
        else {
            this.getClientProfile(this.userId);
            this.createClientForm();
        }
    };
    ProfileComponent.prototype.changeAction = function () {
        this.enableChange = !this.enableChange;
    };
    ProfileComponent.prototype.getProfile = function (staffId) {
        var _this = this;
        this.loading = true;
        this.apiService.get('staff/view/' + staffId).subscribe(function (staff) {
            _this.loading = false;
            if (staff['status'] === 200) {
                _this.userInfo = staff['staff'];
                if (_this.userInfo && _this.userInfo['profile'] && _this.userInfo['profile']['file_path']) {
                    _this.profileImage = _this.userInfo['profile']['file_path'];
                }
                else {
                    _this.profileImage = '';
                }
                _this.setStaffData();
            }
            else {
                _this.userInfo = {};
            }
        }, function (err) {
            _this.loading = false;
        });
    };
    ProfileComponent.prototype.getClientProfile = function (clientId) {
        var _this = this;
        this.loading = true;
        this.apiService.get('client/view/' + clientId).subscribe(function (client) {
            _this.loading = false;
            if (client['status'] === 200) {
                _this.userInfo = client['client'];
                _this.setClientData();
            }
            else {
                _this.userInfo = {};
            }
        }, function (err) {
            _this.loading = false;
        });
    };
    ProfileComponent.prototype.setStaffData = function () {
        this.profileForm.patchValue({
            email: this.userInfo['email'],
            firstName: this.userInfo['staff_firstname'],
            surName: this.userInfo['staff_surname'],
            current_password: '',
            new_password: '',
            confirm_password: '',
        });
    };
    ProfileComponent.prototype.setClientData = function () {
        this.profileForm.patchValue({
            email: this.userInfo['email'],
            name: this.userInfo['name'],
            current_password: '',
            new_password: '',
            confirm_password: '',
        });
    };
    ProfileComponent.prototype.checkPasswords = function (group) {
        var pass = group.get('new_password').value;
        var confirmPass = group.get('confirm_password').value;
        return pass === confirmPass ? null : { notSame: true };
    };
    ProfileComponent.prototype.createForm = function () {
        this.profileForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            firstName: ['', Validators.required],
            surName: ['', Validators.required],
            current_password: [''],
            new_password: [''],
            confirm_password: [''],
        }, { validator: this.checkPasswords });
    };
    ProfileComponent.prototype.createClientForm = function () {
        this.profileForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            name: [''],
            current_password: [''],
            new_password: [''],
            confirm_password: [''],
        }, { validator: this.checkPasswords });
    };
    Object.defineProperty(ProfileComponent.prototype, "f", {
        get: function () {
            return this.profileForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.valuechange = function (value, type) {
        if (type === 'New') {
            if (value.length > 0) {
                this.isPass = false;
            }
            else {
                this.isPass = true;
            }
        }
        if (type === 'Current') {
            if (value.length > 0) {
                this.isCurrent = false;
            }
            else {
                this.isCurrent = true;
            }
        }
    };
    ProfileComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    ProfileComponent.prototype.closeModel = function () {
        this.files = [];
        this.fileError = false;
        this.removeFileIds = [];
        this.profileImageInfo = null;
        this.isDisable = false;
        this.isCurrent = false;
        this.isPass = false;
        this.enableChange = false;
        this.isSubmitted = false;
        this.profileForm.reset();
        if (this.userType === '2') {
            this.getProfile(this.userId);
        }
        else {
            this.getClientProfile(this.userId);
        }
    };
    ProfileComponent.prototype.uploadS3Bucket = function () {
        var _this = this;
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        var type = 4;
        this.isDisable = true;
        this.s3upload
            .uploadfile(this.files[0]['nativeFile'], 0, type)
            .then(function (data) {
            var fileData = { file_path: data['Key'], name: _this.files[0]['nativeFile']['name'] };
            _this.uploadfileList = [fileData];
            if (data && data['Key']) {
                _this.apiService.post('staff/profile-upload', fileData).subscribe(function (fileInfo) {
                    if (fileInfo['status'] === 200) {
                        _this.isDisable = false;
                        _this.profileImage = data['Key'];
                        _this.profileImageInfo = fileInfo['file']['id'];
                    }
                }, function (err) {
                    _this.isDisable = true;
                });
            }
        })
            .catch(function (err) {
            _this.isDisable = false;
        });
    };
    ProfileComponent.prototype.readURL = function () {
        var _this = this;
        var file = this.files[0]['nativeFile'];
        var reader = new FileReader();
        reader.onload = function (e) {
            _this.uploadfileList = [{ imageSrc: reader.result, name: _this.files[0]['nativeFile']['name'] }];
        };
        reader.readAsDataURL(file);
    };
    ProfileComponent.prototype.onUploadOutput = function (output) {
        if (output.type === 'allAddedToQueue') {
            var fileType = this.files[0]['type'];
            var fileSize = this.files[0].size / 1024 / 1024;
            var validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(fileType)) {
                this.fileError = true;
                this.fileFormatError = true;
                this.removeFile(this.files[0]['id']);
            }
            else {
                if (fileSize < 5) {
                    this.readURL();
                    this.uploadS3Bucket();
                }
                else {
                    this.fileError = true;
                    this.fileSizeError = true;
                    this.removeFile(this.files[0]['id']);
                }
            }
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            // add file to array when added
            this.files = [output.file];
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            this.files = [output.file];
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    };
    ProfileComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    ProfileComponent.prototype.removeFile = function (id) {
        this.uploadInput.emit({ type: 'remove', id: id });
    };
    ProfileComponent.prototype.removeAllFiles = function () {
        this.uploadInput.emit({ type: 'removeAll' });
    };
    ProfileComponent.prototype.updateProfile = function () {
        var _this = this;
        this.isSubmitted = true;
        this.isCurrent = false;
        this.isPass = false;
        if (this.profileForm.invalid) {
            return;
        }
        var profileValue = this.profileForm.value;
        if (this.enableChange) {
            if (profileValue && this.profileForm.value['current_password'] === '') {
                this.isCurrent = true;
                return;
            }
            else if (profileValue['current_password'].length > 0) {
                if (profileValue['new_password'] === '') {
                    this.isPass = true;
                }
                else {
                    this.isPass = false;
                }
                if (this.isPass) {
                    return;
                }
            }
        }
        var params = { userLogId: this.userInfo['userLogId'] };
        params['email'] = profileValue.email;
        if (this.userType == '2') {
            params['firstName'] = profileValue.firstName;
            params['surName'] = profileValue.surName;
            if (this.profileImageInfo)
                params['profile_image'] = this.profileImageInfo;
        }
        else {
            params['name'] = profileValue.name;
        }
        if (this.enableChange) {
            params['current_password'] = profileValue.current_password;
            params['new_password'] = profileValue.new_password;
            params['confirm_password'] = profileValue.confirm_password;
        }
        var apiUrl = this.userType === '2' ? 'auth/update-staffprofile/' + this.userId : 'auth/update-clientprofile/' + this.userId;
        this.isDisable = true;
        this.loading = true;
        this.apiService.post(apiUrl, params).subscribe(function (data) {
            if (data['status']['status'] === 200) {
                _this.closeModel();
                _this.toastr.success(data['status']['message']);
                _this.apiService.profileUpdates.emit(1);
            }
            else {
                _this.loading = false;
                _this.isDisable = false;
                _this.toastr.error(data['status']['message']);
            }
        }, function (err) {
            _this.isDisable = false;
            _this.loading = false;
            if (err.error.message) {
                _this.toastr.error(err.error.message);
            }
        });
    };
    return ProfileComponent;
}());
export { ProfileComponent };
