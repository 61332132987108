import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { GeolocationService } from '../services';
var CustomerCreationComponent = /** @class */ (function () {
    function CustomerCreationComponent(geolocationService, formBuilder, router, toastr, modalService, apiService) {
        this.geolocationService = geolocationService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.toastr = toastr;
        this.modalService = modalService;
        this.apiService = apiService;
        this.submitted = false;
        this.isEdit = false;
        this.phoneCode = [];
        this.editdetailsarray = [];
        this.defaultCountryCode = 'NZ';
        this.timeformatvalue = '';
        this.dateformatvalue = '';
        this.domainInfo = {};
    }
    CustomerCreationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getCurrentLocation();
        this.getPhoneCode();
        this.apiService.closeClientModel.subscribe(function (data) {
            if (data === 11) {
                _this.timeformatpopup();
            }
            if (data === 12) {
                // this.search = '';
                _this.timezonepopup();
            }
            if (data === 13) {
                // this.search = '';
                _this.dateformatpopup();
            }
        });
        this.customercreationform = this.formBuilder.group({
            Name: ['', Validators.required],
            CompanyName: ['', Validators.required],
            CompanyWebsite: ['', Validators.required],
            countryCode: ['', Validators.required],
            MobileNumber: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email]],
            Domain: ['', Validators.required],
            TimeFormat: ['', Validators.required],
            TimeZone: ['', Validators.required],
            DateFormat: ['', Validators.required],
        });
        var a = JSON.parse(localStorage.getItem('Editarray'));
        if (a == null) {
            this.isEdit = false;
        }
        else {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.editdetailsarray = a;
            this.editdetailspopulate();
        }
        // alert("2")
    };
    CustomerCreationComponent.prototype.getCurrentLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.geolocationService.getCurrentCountry()];
                    case 1:
                        _a.defaultCountryCode = _b.sent();
                        this.setcountryFlag();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerCreationComponent.prototype.setCountryInfo = function (code) {
        this.setcountrycodeflag(code);
    };
    CustomerCreationComponent.prototype.getPhoneCode = function () {
        var _this = this;
        this.apiService.get('phone-code').subscribe(function (phoneCode) {
            if (phoneCode.status === 200) {
                _this.phoneCode = phoneCode['phonecodes'];
                if (_this.phoneCode && _this.phoneCode.length > 0) {
                    _this.setcountryFlag();
                }
            }
        });
    };
    CustomerCreationComponent.prototype.setcountryFlag = function () {
        var _this = this;
        var intphoneData = this.phoneCode.filter(function (obj) { return obj.countries_iso_code === _this.defaultCountryCode; });
        if (!this.isEdit) {
            if (intphoneData && intphoneData.length > 0) {
                this.setcountrycodeflag(intphoneData[0]);
            }
        }
        else {
            if (this.isEdit) {
                var phoneData = this.phoneCode.filter(function (obj) { return obj.id === _this.editdetailsarray['countryCode']; });
                if (phoneData && phoneData.length > 0) {
                    this.setcountrycodeflag(phoneData[0]);
                }
                else {
                    this.setcountrycodeflag(intphoneData[0]);
                }
            }
        }
    };
    CustomerCreationComponent.prototype.setcountrycodeflag = function (intphoneData) {
        this.countryCode = intphoneData.countries_isd_code;
        this.countryFlag = intphoneData.countries_iso_code;
        this.nzcode = intphoneData.id;
        this.customercreationform.patchValue({
            countryCode: this.nzcode,
        });
    };
    CustomerCreationComponent.prototype.editdetailspopulate = function () {
        this.timeformatvalue = this.editdetailsarray['timeformat'];
        this.dateformatvalue = this.editdetailsarray['dateformat'];
        var timeformat = '';
        var dateformat = '';
        if (this.timeformatvalue == 'hh:mm @') {
            timeformat = moment().format('hh:mm A');
        }
        if (this.timeformatvalue == 'hh:mm:ss @') {
            timeformat = moment().format('hh:mm:ss A');
        }
        if (this.timeformatvalue == 'HH:mm') {
            timeformat = moment().format('HH:mm');
        }
        if (this.timeformatvalue == 'HH:mm:ss') {
            timeformat = moment().format('HH:mm:ss');
        }
        // dateformat
        if (this.dateformatvalue == 'dd/MM/yyyy') {
            dateformat = moment().format('DD/MM/YYYY');
        }
        if (this.dateformatvalue == 'MM/dd/yyyy') {
            dateformat = moment().format('MM/DD/YYYY');
        }
        if (this.dateformatvalue == 'yyyy/MM/dd') {
            dateformat = moment().format('YYYY/MM/DD');
        }
        if (this.dateformatvalue == 'MMM dd,yyyy') {
            dateformat = moment().format('MMMM DD, YYYY');
        }
        if (this.dateformatvalue == 'dd MMM, yyyy') {
            dateformat = moment().format('DD MMMM, YYYY');
        }
        // var dateformat =moment(this.editdetailsarray['dateformat']).format("YYYY-MM-DD")
        // let countrycodeflag = true;
        // if (this.editdetailsarray['countryCode'] === null) {
        //   countrycodeflag = false;
        // } else {
        //   countrycodeflag = true;
        // }
        this.customercreationform.patchValue({
            Name: this.editdetailsarray['name'],
            CompanyName: this.editdetailsarray['clientname'],
            CompanyWebsite: this.editdetailsarray['companyWebsite'],
            MobileNumber: this.editdetailsarray['mobileNumber'],
            Email: this.editdetailsarray['email'],
            Domain: this.editdetailsarray['subdomain'],
            TimeFormat: timeformat,
            TimeZone: this.editdetailsarray['timezone'],
            DateFormat: dateformat,
        });
    };
    CustomerCreationComponent.prototype.isNumberKey = function (evt, value) {
        //if (evt != 43 && evt > 31 && (evt < 48 || evt > 57)){
        if (evt > 31 && (evt < 48 || evt > 57)) {
            return false;
        }
        else if (value.length > 14) {
            return false;
        }
        return true;
    };
    CustomerCreationComponent.prototype.receivetimeformat = function ($event) {
        this.message = $event;
        this.customercreationform.controls['TimeFormat'].setValue(this.message);
    };
    CustomerCreationComponent.prototype.receivetime = function ($event) {
        this.timeformatvalue = $event;
    };
    CustomerCreationComponent.prototype.receivedate = function ($event) {
        this.dateformatvalue = $event;
    };
    CustomerCreationComponent.prototype.receivedateformat = function ($event) {
        this.message2 = $event;
        this.customercreationform.controls['DateFormat'].setValue(this.message2);
    };
    CustomerCreationComponent.prototype.receivetimezonevalue = function ($event) {
        this.message3 = $event;
        this.customercreationform.controls['TimeZone'].setValue(this.message3);
    };
    Object.defineProperty(CustomerCreationComponent.prototype, "f", {
        get: function () {
            return this.customercreationform.controls;
        },
        enumerable: true,
        configurable: true
    });
    CustomerCreationComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.customercreationform.invalid) {
            return;
        }
        var object = {};
        object['name'] = this.customercreationform.get('Name').value;
        object['clientname'] = this.customercreationform.get('CompanyName').value;
        object['companyWebsite'] = this.customercreationform.get('CompanyWebsite').value;
        object['mobileNumber'] = this.customercreationform.get('MobileNumber').value;
        object['countryCode'] = this.customercreationform.get('countryCode').value;
        object['email'] = this.customercreationform.get('Email').value;
        object['domain'] = this.customercreationform.get('Domain').value;
        object['timeFormat'] = this.timeformatvalue;
        object['dateFormat'] = this.dateformatvalue;
        object['timezone'] = this.customercreationform.get('TimeZone').value;
        if (this.isEdit) {
            this.apiService.put('domain/update-domain/' + this.editdetailsarray['id'], object).subscribe(function (client) {
                _this.successMessage(client);
            });
        }
        else {
            this.apiService.post('domain/create-domain', object).subscribe(function (client) {
                _this.successMessage(client);
            }, function (err) {
                if (err.error.message) {
                    _this.toastr.error(err.error.message);
                }
            });
        }
        this.router.navigate(['/client-information']);
    };
    CustomerCreationComponent.prototype.successMessage = function (client) {
        if (client.status === 200) {
            this.toastr.success(client.message);
        }
    };
    CustomerCreationComponent.prototype.closeModel = function () {
        this.router.navigate(['/client-information']);
    };
    CustomerCreationComponent.prototype.timeformatModal = function (template) {
        this.modalRef1 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    CustomerCreationComponent.prototype.timezoneModal = function (template) {
        this.modalRef2 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    CustomerCreationComponent.prototype.dateformatModal = function (template) {
        this.modalRef3 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    CustomerCreationComponent.prototype.timeformatpopup = function () {
        this.modalRef1.hide();
        // this.apiService.searchValue.emit({id:2,value:this.search, showHidden: this.showHidden});
    };
    CustomerCreationComponent.prototype.timezonepopup = function () {
        this.modalRef2.hide();
        // this.apiService.searchValue.emit({id:2,value:this.search, showHidden: this.showHidden});
    };
    CustomerCreationComponent.prototype.dateformatpopup = function () {
        this.modalRef3.hide();
        // this.apiService.searchValue.emit({id:2,value:this.search, showHidden: this.showHidden});
    };
    return CustomerCreationComponent;
}());
export { CustomerCreationComponent };
