<div class="container-fluid p-4 bg-white">
  <div class="row px-3">
    <div class="col-12">
      <img src="./assets/images/r101img.png" class="img-responsive r101img" alt="icon" />
    </div>
  </div>
  <div class="row px-5 py-5">
    <div class="col-lg-6">
      <form [formGroup]="customercreationform" (ngSubmit)="onSubmit()">
        <div class="row pb-3">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Name</label>
              <input
                type="text"
                formControlName="Name"
                class="form-control customercreateform"
                placeholder="Enter Name"
                [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
              />
              <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                <div *ngIf="f.Name.errors.required">Name is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Company Name</label>
              <input
                type="text"
                formControlName="CompanyName"
                class="form-control customercreateform"
                placeholder="Enter Company Name"
                [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }"
              />
              <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
                <div *ngIf="f.CompanyName.errors.required">CompanyName is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Company Website</label>
              <input
                type="text"
                formControlName="CompanyWebsite"
                class="form-control customercreateform"
                placeholder="Enter Company Website"
                [ngClass]="{ 'is-invalid': submitted && f.CompanyWebsite.errors }"
              />
              <div *ngIf="submitted && f.CompanyWebsite.errors" class="invalid-feedback">
                <div *ngIf="f.CompanyWebsite.errors.required">CompanyWebsite is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-lg-6">
            <label class="fs20 color-black">Mobile Number</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="btn-group about-more number-flag" dropdown>
                  <button
                    id="button-basic"
                    dropdownToggle
                    type="button"
                    class="btn btn-primary custombtninputgrp dropdown-toggle border bg-transparent fs14 text-dark"
                    aria-controls="dropdown-basic"
                  >
                    <img src="assets/images/country-flags/{{ countryFlag }}.png" alt="country" /> {{ countryCode }}
                    <em class="fas fa-caret-down ml-1 op-05"></em>
                  </button>
                  <ul
                    id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="button-basic"
                    formControlName="countryCode"
                    [ngClass]="{ 'is-invalid': submitted && f.countryCode.errors }"
                  >
                    <li role="menuitem" *ngFor="let code of phoneCode">
                      <span (click)="setCountryInfo(code)">
                        <img src="assets/images/country-flags/{{ code.countries_iso_code }}.png" alt="country" />
                        {{ code.countries_isd_code }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="number"
                (keypress)="isNumberKey($event.keyCode, $event.target.value)"
                formControlName="MobileNumber"
                class="form-control custominputgroup customercreateform"
                placeholder="Mobile Number"
                [ngClass]="{ 'is-invalid': submitted && f.MobileNumber.errors }"
              />
              <div *ngIf="submitted && f.MobileNumber.errors" class="invalid-feedback">
                <div *ngIf="f.MobileNumber.errors.required">MobileNumber is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Email</label>
              <input
                type="text"
                formControlName="Email"
                class="form-control customercreateform"
                placeholder="Enter Email"
                [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
              />
              <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                <div *ngIf="f.Email.errors.required">Email is required</div>
                <div *ngIf="f.Email.errors.email">Email is not valid</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Domain</label>
              <input
                type="text"
                formControlName="Domain"
                class="form-control customercreateform"
                placeholder="Enter Domain"
                [ngClass]="{ 'is-invalid': submitted && f.Domain.errors }"
              />
              <div *ngIf="submitted && f.Domain.errors" class="invalid-feedback">
                <div *ngIf="f.Domain.errors.required">Domain is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Time Format</label>
              <input
                type="text"
                (click)="timeformatModal(Timeformat)"
                formControlName="TimeFormat"
                class="form-control customercreateform"
                placeholder="Enter Time Format"
                [ngClass]="{ 'is-invalid': submitted && f.TimeFormat.errors }"
              />
              <div *ngIf="submitted && f.TimeFormat.errors" class="invalid-feedback">
                <div *ngIf="f.TimeFormat.errors.required">TimeFormat is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Time Zone</label>
              <input
                type="text"
                (click)="timezoneModal(Timezone)"
                formControlName="TimeZone"
                class="form-control customercreateform"
                placeholder="Enter Time Zone"
                [ngClass]="{ 'is-invalid': submitted && f.TimeZone.errors }"
              />
              <div *ngIf="submitted && f.TimeZone.errors" class="invalid-feedback">
                <div *ngIf="f.TimeZone.errors.required">TimeZone is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="fs20 color-black">Date Format</label>
              <input
                type="text"
                (click)="dateformatModal(Dateformat)"
                formControlName="DateFormat"
                class="form-control customercreateform"
                placeholder="Enter Date Format"
                [ngClass]="{ 'is-invalid': submitted && f.DateFormat.errors }"
              />
              <div *ngIf="submitted && f.DateFormat.errors" class="invalid-feedback">
                <div *ngIf="f.DateFormat.errors.required">DateFormat is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-2">
          <div class="col-lg-6 mb-4 d-flex justify-content-start">
            <button
              type="button"
              class="btn btn-primary btn-cancel border-0 fs14 w-140px mr-3"
              tooltip="Cancel"
              (click)="closeModel()"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-secondary btn-create border-0 w-140px fs14" tooltip="New Staff">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-6 pl-lg-5">
      <img src="./assets/images/customer-creationimg.svg" class="pr-2 w-100 img-responsive" alt="icon" />
    </div>
  </div>
  <ng-template #Timeformat>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Time Format</h4>
      <button
        type="button"
        class="close text-white font-weight-normal op1"
        aria-label="Close"
        (click)="timeformatpopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-timeformat (messageEvent)="receivetimeformat($event)" (timedformat)="receivetime($event)"></app-timeformat>
    </div>
  </ng-template>
  <ng-template #Dateformat>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Date Format</h4>
      <button
        type="button"
        class="close text-white font-weight-normal op1"
        aria-label="Close"
        (click)="dateformatpopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-dateformat (messageEvent)="receivedateformat($event)" (datesformat)="receivedate($event)"></app-dateformat>
    </div>
  </ng-template>
  <ng-template #Timezone>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Time Format</h4>
      <button
        type="button"
        class="close text-white font-weight-normal op1"
        aria-label="Close"
        (click)="timezonepopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-timezone (messageEvent)="receivetimezonevalue($event)"></app-timezone>
    </div>
  </ng-template>
</div>
