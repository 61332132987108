<div class="px-3">
  <h1 class="color-black op-09 fs16">New Staff</h1>
  <p class="color-black op-05 fs14 mb-0">Please fill in this form and select 'New Staff' to add a new staff member.</p>
  <form class="modal-form mt-3" [formGroup]="staffForm" (ngSubmit)="createStaff()">
    <div class="form-row">
      <div class="col form-group">
        <label for="firstName" [ngClass]="{ 'text-danger': isSubmitted && f.firstName.errors }">First Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="First Name"
          id="firstName"
          formControlName="firstName"
          [ngClass]="{ 'invalid-field': isSubmitted && f.firstName.errors }"
          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
        />
      </div>
      <div class="col form-group">
        <label for="Surname" [ngClass]="{ 'text-danger': isSubmitted && f.surName.errors }">Surname</label>
        <input
          type="text"
          class="form-control"
          placeholder="Surname"
          id="Surname"
          formControlName="surName"
          [ngClass]="{ 'invalid-field': isSubmitted && f.surName.errors }"
          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
        />
      </div>
    </div>
    <div class="form-group">
      <label for="Role">Role</label>
      <input
        type="text"
        class="form-control"
        placeholder="Role"
        id="Role"
        formControlName="role"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
      />
    </div>
    <div class="form-row">
      <label>Phone Number</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="btn-group about-more number-flag" dropdown>
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn btn-primary dropdown-toggle border bg-transparent fs14 text-dark"
              aria-controls="dropdown-basic"
            >
              <img src="assets/images/country-flags/{{ countryFlag }}.png" alt="country" /> {{ countryCode }}
              <em class="fas fa-caret-down ml-1 op-05"></em>
            </button>
            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-basic"
              formControlName="phone_code"
              [ngClass]="{ 'invalid-field': isSubmitted && f.phone_code.errors }"
            >
              <li role="menuitem" *ngFor="let code of phoneCode">
                <span (click)="setCountryInfo(code)">
                  <img src="assets/images/country-flags/{{ code.countries_iso_code }}.png" alt="country" />
                  {{ code.countries_isd_code }}</span
                >
              </li>
            </ul>
          </div>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="00000 00000"
          formControlName="phone"
          (keypress)="isNumberKey($event.keyCode, $event.target.value)"
          [ngClass]="{ 'invalid-field': isPhone }"
        />
      </div>
    </div>
    <div class="form-group">
      <label [ngClass]="{ 'text-danger': isSubmitted && f.email.errors }" for="email">Email</label>
      <input
        type="email"
        class="form-control"
        placeholder="abcd@example.com"
        formControlName="email"
        [ngClass]="{ 'invalid-field': (isSubmitted && f.email.errors) || isEmail }"
        id="email"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
      />
      <div *ngIf="f.email.errors" class="text-danger mt-1">
        <p class="mb-0" *ngIf="f.email.errors.email">Please Enter Valid Email</p>
      </div>
      <div *ngIf="isEmail" class="text-danger mt-1">
        <p class="mb-0" *ngIf="isEmail">E-Mail Id already exists</p>
      </div>
    </div>
    <div class="form-group">
      <label [ngClass]="{ 'text-danger': fileError }">Profile Image</label>
      <div class="image-upload-wrap">
        <div
          class="drop-container"
          ngFileDrop
          [options]="options"
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="uploadInput"
          [ngClass]="{ 'is-drop-over': dragOver }"
        >
          <input
            #fileInput
            class="file-upload-input"
            type="file"
            ngFileSelect
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            accept="image/*"
          />
          <div class="drag-text text-center color-dark-blue">
            <h3 class="fs18 mb-0">
              <span class="op-25">Drag and Drop your Files here<span class="drag-or">Or</span> </span
              ><button class="btn btn-secondary btn-create border-0 fs14 my-2">Upload your Files</button>
            </h3>
          </div>
        </div>
        <!-- Start File list -->
        <ul class="list-group mt-4" *ngIf="uploadfileList.length > 0">
          <li class="list-group-item my-2" *ngFor="let fileInfo of uploadfileList; let i = index">
            <div class="media aliign-items-center flex-column flex-sm-row">
              <img
                src="{{ fileUrl }}{{ fileInfo?.file_path }}"
                alt="Profile"
                class="roaster-profile-img align-self-center rounded-circle mr-sm-3"
                *ngIf="fileInfo?.file_path"
              />
              <img
                [src]="fileInfo?.imageSrc"
                alt="Profile"
                class="roaster-profile-img align-self-center rounded-circle mr-3"
                *ngIf="!fileInfo?.file_path"
              />
              <div class="media-body align-self-center my-3 my-sm-0">
                <h1 class="fs18 color-light-black mt-3">{{ fileInfo.name }}</h1>
                <progressbar [value]="progress[i].progress" [striped]="true" class="w-50"
                  >{{ progress[i].progress }}%</progressbar
                >
              </div>
              <div class="align-self-center">
                <em class="far fa-times-circle c-pointer fs20" (click)="removeUploadFile(fileInfo)"></em>
              </div>
            </div>
          </li>
        </ul>
        <!-- End File list -->
      </div>
      <div *ngIf="fileError">
        <p class="text-danger" *ngIf="fileSizeError">*Large file. File Allowed Maximum 5MB.</p>
        <p class="text-danger" *ngIf="fileFormatError">Valid extenstions {{ supportFormat }}</p>
      </div>
    </div>
    <div class="custom-control custom-checkbox" *ngIf="!isEdit">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="is_newsletter_subscribed"
        id="is_newsletter_subscribed"
      />
      <label class="custom-control-label font-weight-bold" for="is_newsletter_subscribed"
        >Subscribe R101 Newsletter</label
      >
    </div>
    <div class="custom-control custom-checkbox" *ngIf="isEdit">
      <input type="checkbox" class="custom-control-input" formControlName="send_message" id="send_message" />
      <label class="custom-control-label font-weight-bold" for="send_message">Send Messages To Anyone</label>
    </div>
    <div class="custom-control custom-checkbox" *ngIf="isEdit">
      <input type="checkbox" class="custom-control-input" formControlName="receive_message" id="receive_message" />
      <label class="custom-control-label font-weight-bold" for="receive_message">Receive Message From Anyone</label>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button
        type="button"
        class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
        tooltip="Cancel"
        (click)="closeModel()"
      >
        Cancel
      </button>
      <button
        type="submit"
        [disabled]="loading"
        class="btn btn-secondary btn-create border-0 w-100px fs14"
        tooltip="New Staff"
        *ngIf="!isEdit"
      >
        New Staff
      </button>
      <button
        type="submit"
        [disabled]="loading"
        class="btn btn-secondary btn-create border-0 fs14"
        tooltip="Update Staff"
        *ngIf="isEdit"
      >
        Update Staff
      </button>
    </div>
  </form>
</div>
