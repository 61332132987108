<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500"><a>profile</a></li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Profile</h1>
    <p class="color-grey-text pl-2">
      You can change your personal login information using the form below. To change your password please click on
      "Change Password".
    </p>
    <div class="bg-whit p-4">
      <form [formGroup]="profileForm">
        <div class="col-lg-8 px-0">
          <div class="row">
            <div class="col-md-6" *ngIf="userType == '2'">
              <h2 class="fs20" [ngClass]="{ 'text-danger': fileError }">Update profile photo</h2>
              <div class="file-upload" *ngIf="profileImage == ''">
                <div class="image-upload">
                  <input
                    #fileInput
                    class="file-upload-inpu"
                    type="file"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    accept="image/*"
                    (click)="fileInput.value = null"
                  />
                  <div class="drag-tex">
                    <em class="fa fa-upload text-center transform" aria-hidden="true"></em>
                    <h3 class="fs16 text-center">upload</h3>
                  </div>
                </div>
              </div>
              <div class="updated-image mx-auto" *ngIf="profileImage != ''">
                <img
                  src="{{ fileUrl }}{{ profileImage }}"
                  class="rounded-circle"
                  width="136"
                  height="136"
                  alt="Profile Picture"
                />
                <div class="overlay">
                  <a href="javascript:void(0)" (click)="fileInput.click()">
                    <em class="fa fa-edit"></em>
                    <input
                      #fileInput
                      class="file-upload-inpu"
                      type="file"
                      ngFileSelect
                      [options]="options"
                      (uploadOutput)="onUploadOutput($event)"
                      [uploadInput]="uploadInput"
                      accept="image/*"
                      (click)="fileInput.value = null"
                    />
                  </a>
                </div>
              </div>
              <div *ngIf="fileError">
                <p class="text-danger" *ngIf="fileSizeError">*Large file. File Allowed Maximum 5MB.</p>
                <p class="text-danger" *ngIf="fileFormatError">Valid extenstions {{ supportFormat }}</p>
              </div>
            </div>
            <div class="" [ngClass]="userType == '2' ? 'col-md-6' : 'col-md-12'">
              <div class="float-md-right mt-3 mt-md-0">
                <button
                  type="button"
                  class="btn btn-secondary btn-create border-0 mr-3 fs14 px-3"
                  (click)="changeAction()"
                  tooltip="{{ enableChange ? 'Hide Password' : 'Enable Password' }}"
                >
                  Change Password
                </button>
              </div>
            </div>

            <div class="form-group col-md-6 mt-4" *ngIf="userType == '2'">
              <label for="event" [ngClass]="{ 'text-danger': isSubmitted && f.firstName.errors }">
                <span class="font-weight-bold">First Name</span></label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter First Name"
                formControlName="firstName"
                id="event"
                [ngClass]="{ 'invalid-field': isSubmitted && f.firstName.errors }"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
            </div>
            <div class="form-group col-md-6 mt-4" *ngIf="userType == '2'">
              <label class="font-weight-bold" for="code" [ngClass]="{ 'text-danger': isSubmitted && f.surName.errors }"
                >Sur Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter SurName"
                formControlName="surName"
                id="code"
                [ngClass]="{ 'invalid-field': isSubmitted && f.surName.errors }"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
            </div>
            <div class="form-group col-md-12" *ngIf="userType == '1'">
              <label class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && f.name.errors }">Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="Enter Name"
                [ngClass]="{ 'invalid-field': isSubmitted && f.name.errors }"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
            </div>
            <div class="form-group col-md-12">
              <label class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && f.email.errors }">Email</label>
              <input
                type="text"
                class="form-control"
                formControlName="email"
                placeholder="Enter Email"
                [ngClass]="{ 'invalid-field': isSubmitted && f.email.errors }"
                id="email"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
              <div *ngIf="f.email.errors" class="text-danger mt-1">
                <p class="mb-0" *ngIf="f.email.errors.email">Please Enter Valid Email</p>
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="enableChange">
              <label class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && isCurrent }"
                >Current Password</label
              >
              <input
                type="password"
                class="form-control"
                formControlName="current_password"
                placeholder="Current Password"
                [ngClass]="{ 'invalid-field': isSubmitted && isCurrent }"
                (input)="valuechange($event.target.value, 'Current')"
              />
            </div>
            <div class="form-group col-md-12" *ngIf="enableChange && userType == '2'">
              <label class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && isPass }">New Password</label>
              <input
                type="password"
                class="form-control"
                formControlName="new_password"
                placeholder="New Password"
                pattern="{{ passwordRegEx }}"
                [ngClass]="{ 'invalid-field': isSubmitted && isPass }"
                (input)="valuechange($event.target.value, 'New')"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
              <div *ngIf="f.new_password.errors" class="text-danger mt-1">
                <p class="mb-0" *ngIf="f.new_password.errors.pattern">
                  Password requires at least 8 characters, one must be a number and one special character
                </p>
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="enableChange && userType == '1'">
              <label class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && isPass }">New Password</label>
              <input
                type="password"
                class="form-control"
                formControlName="new_password"
                placeholder="New Password"
                pattern="{{ passwordRegEx }}"
                [ngClass]="{ 'invalid-field': isSubmitted && isPass }"
                (input)="valuechange($event.target.value, 'New')"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
              <div *ngIf="f.new_password.errors" class="text-danger mt-1">
                <p class="mb-0" *ngIf="f.new_password.errors.pattern">
                  Password requires at least 8 characters, one must be a number and one special character
                </p>
              </div>
            </div>
            <div class="form-group col-md-12" *ngIf="enableChange">
              <label class="font-weight-bold" [ngClass]="{ 'text-danger': profileForm.hasError('notSame') }"
                >Confirm Password</label
              >
              <input
                type="password"
                class="form-control"
                formControlName="confirm_password"
                placeholder="Confirm New Password"
                [ngClass]="{ 'invalid-field': profileForm.hasError('notSame') }"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              />
              <p
                class="text-danger"
                *ngIf="
                  profileForm.hasError('notSame') &&
                  profileForm.value &&
                  profileForm.value.confirm_password &&
                  profileForm.value.confirm_password.length > 0
                "
              >
                Password Mismatch
              </p>
            </div>

            <button
              type="submit"
              class="btn btn-primary btn-create border-0 fs14 w-100px ml-3 mt-3"
              tooltip="Update"
              [disabled]="isDisable"
              (click)="updateProfile()"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
