import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService, S3UploadService } from '../services';
import { humanizeBytes } from 'ngx-uploader';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { passwordRegEx } from '../constants/defaultValues';
var ViewprofileComponent = /** @class */ (function () {
    function ViewprofileComponent(toastr, formBuilder, s3upload, apiService) {
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.s3upload = s3upload;
        this.apiService = apiService;
        this.submitted = false;
        this.isPass = false;
        this.isCurrent = false;
        this.fileUrl = environment.fileUrl;
        this.isDisable = false;
        this.profileImage = '';
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.supportFormat = 'png,jpg,gif,jpeg';
        this.uploadfileList = [];
        this.progress = [{ progress: 0 }];
        this.removeFileIds = [];
        this.userId = localStorage.getItem('userid');
        this.passwordRegEx = passwordRegEx;
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
    }
    ViewprofileComponent.prototype.ngOnInit = function () {
        this.viewprofileform = this.formBuilder.group({
            Name: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email]],
            currentpassword: ['', Validators.required],
            newpassword: ['', Validators.required],
            confirmpassword: ['', Validators.required],
        }, { validator: this.checkPasswords });
        this.viewprofileform.patchValue({
            Name: this.info['staff_firstname'],
            Email: this.info['email'],
        });
        this.profileImage = this.info.profile.file_path;
    };
    ViewprofileComponent.prototype.checkPasswords = function (group) {
        var pass = group.get('newpassword').value;
        var confirmPass = group.get('confirmpassword').value;
        return pass === confirmPass ? null : { notSame: true };
    };
    Object.defineProperty(ViewprofileComponent.prototype, "f", {
        get: function () {
            return this.viewprofileform.controls;
        },
        enumerable: true,
        configurable: true
    });
    ViewprofileComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    ViewprofileComponent.prototype.valuechange = function (value, type) {
        if (type === 'New') {
            if (value.length > 0) {
                this.isPass = false;
            }
            else {
                this.isPass = true;
            }
        }
        if (type === 'Current') {
            if (value.length > 0) {
                this.isCurrent = false;
            }
            else {
                this.isCurrent = true;
            }
        }
    };
    ViewprofileComponent.prototype.uploadS3Bucket = function () {
        var _this = this;
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        var type = 4;
        this.isDisable = true;
        this.s3upload
            .uploadfile(this.files[0]['nativeFile'], 0, type)
            .then(function (data) {
            var fileData = { file_path: data['Key'], name: _this.files[0]['nativeFile']['name'] };
            _this.uploadfileList = [fileData];
            if (data && data['Key']) {
                _this.apiService.post('staff/profile-upload', fileData).subscribe(function (fileInfo) {
                    if (fileInfo['status'] === 200) {
                        _this.isDisable = false;
                        _this.profileImage = data['Key'];
                        _this.profileImageInfo = fileInfo['file']['id'];
                    }
                }, function (err) {
                    _this.isDisable = true;
                });
            }
        })
            .catch(function (err) {
            _this.isDisable = false;
        });
    };
    ViewprofileComponent.prototype.readURL = function () {
        var _this = this;
        var file = this.files[0]['nativeFile'];
        var reader = new FileReader();
        reader.onload = function (e) {
            _this.uploadfileList = [{ imageSrc: reader.result, name: _this.files[0]['nativeFile']['name'] }];
        };
        reader.readAsDataURL(file);
    };
    ViewprofileComponent.prototype.onUploadOutput = function (output) {
        if (output.type === 'allAddedToQueue') {
            var fileType = this.files[0]['type'];
            var fileSize = this.files[0].size / 1024 / 1024;
            var validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(fileType)) {
                this.fileError = true;
                this.fileFormatError = true;
                this.removeFile(this.files[0]['id']);
            }
            else {
                if (fileSize < 5) {
                    this.readURL();
                    this.uploadS3Bucket();
                }
                else {
                    this.fileError = true;
                    this.fileSizeError = true;
                    this.removeFile(this.files[0]['id']);
                }
            }
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            // add file to array when added
            this.files = [output.file];
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            this.files = [output.file];
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    };
    ViewprofileComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    ViewprofileComponent.prototype.removeFile = function (id) {
        this.uploadInput.emit({ type: 'remove', id: id });
    };
    ViewprofileComponent.prototype.removeAllFiles = function () {
        this.uploadInput.emit({ type: 'removeAll' });
    };
    ViewprofileComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.viewprofileform.invalid) {
            return;
        }
        var params = {};
        if (this.profileImageInfo)
            params['profile_image'] = this.profileImageInfo;
        params['firstName'] = this.viewprofileform.value.Name;
        params['surName'] = this.viewprofileform.value.Name;
        params['email'] = this.viewprofileform.value.Email;
        params['userLogId'] = this.info['userLogId'];
        params['current_password'] = this.viewprofileform.value.currentpassword;
        params['new_password'] = this.viewprofileform.value.newpassword;
        params['confirm_password'] = this.viewprofileform.value.confirmpassword;
        this.apiService.post('auth/update-staffprofile/' + this.userId, params).subscribe(function (client) {
            if (client.status.status === 200) {
                // this.apiService.profilechangemodal.emit("profilechanged");
                // var s =  JSON.parse(localStorage.getItem('user'));
                // localStorage.setItem('user', JSON.stringify(client.userinfo));
                _this.toastr.success(client.status.message);
                _this.closeModel();
            }
        }, function (err) {
            if (err.error.message) {
                _this.toastr.error(err.error.message);
            }
        });
    };
    ViewprofileComponent.prototype.closeModel = function () {
        this.apiService.closeClientModel.emit(15);
    };
    return ViewprofileComponent;
}());
export { ViewprofileComponent };
