import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(apiService, toastr, router) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.router = router;
        this.submitted = false;
        this.loading = false;
        this.isRemember = false;
        this.domainInfo = {};
        this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
        if (this.domainInfo)
            moment.tz.setDefault(this.domainInfo.timezone);
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.createFormControls();
        this.createForm();
    };
    LoginComponent.prototype.createFormControls = function () {
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.password = new FormControl('', [Validators.required]);
    };
    LoginComponent.prototype.createForm = function () {
        this.loginForm = new FormGroup({
            email: this.email,
            password: this.password,
        });
        if (localStorage.getItem('isRemember')) {
            this.isRemember = true;
            var dmail = window.atob(localStorage.getItem('user_email'));
            this.loginForm.controls['email'].setValue(dmail);
            var dpassword = window.atob(localStorage.getItem('user_password'));
            this.loginForm.controls['password'].setValue(dpassword);
        }
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.getDateAndTimeFormat = function (organizationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.apiService.get('domain/getTimeAndDateFormat/' + organizationId).subscribe(function (dateAndTimeInfo) {
                            if (dateAndTimeInfo['status'] === 200) {
                                _this.domainInfo = dateAndTimeInfo && dateAndTimeInfo['viewDateAndTimeFormat'];
                            }
                            else {
                                _this.domainInfo = { timeformat: 'hh:mm:ss @', dateformat: 'MMM DD, YYYY' };
                            }
                            if (_this.domainInfo && _this.domainInfo.timezone)
                                moment.tz.setDefault(_this.domainInfo.timezone);
                            localStorage.setItem('domainInfo', JSON.stringify(_this.domainInfo));
                            resolve(true);
                        });
                    })];
            });
        });
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        var data = {};
        data.email = this.loginForm.value.email;
        data.password = this.loginForm.value.password;
        // data.companyName = 'optisol';
        // data.is_admin = 1;
        this.loading = true;
        this.apiService.post('auth/login', data).subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var queryParams;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = false;
                        if (!(user.status === 200)) return [3 /*break*/, 2];
                        localStorage.setItem('user', JSON.stringify(user.userinfo));
                        localStorage.setItem('userid', user.userinfo.id);
                        localStorage.setItem('token', user.token);
                        localStorage.setItem('userType', user.userinfo.userType);
                        localStorage.setItem('userGroup', user.userinfo.group);
                        localStorage.setItem('userLogId', user.userinfo.userLogId);
                        localStorage.setItem('organization_id', user.userinfo.organization_id);
                        return [4 /*yield*/, this.getDateAndTimeFormat(user.userinfo.organization_id)];
                    case 1:
                        _a.sent();
                        this.toastr.success(user.message);
                        this.checkIsRemember(data);
                        if (user.userinfo && (user.userinfo.group === 'Full access' || user.userinfo.group === 'Advanced')) {
                            this.router.navigate(['/dashboard']);
                        }
                        else {
                            if (user.userinfo.group_id == 5) {
                                queryParams = { page: 1, pagesize: 10 };
                                this.apiService.get('domain/domainList', queryParams).subscribe(function (client) {
                                    if (client.status === 200) {
                                        var list = client.organizationdetails;
                                        localStorage.removeItem('Editarray');
                                        if (list.length > 0) {
                                            _this.router.navigate(['/client-information']);
                                        }
                                        else {
                                            _this.router.navigate(['/customer-creation']);
                                        }
                                    }
                                });
                            }
                            else {
                                this.router.navigate(['/scheduler']);
                            }
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }, function (err) {
            _this.loading = false;
            if (err.error.message) {
                _this.toastr.error(err.error.message);
            }
        });
    };
    LoginComponent.prototype.checkIsRemember = function (data) {
        if (this.isRemember === true || this.isRemember === 'true') {
            var email = window.btoa(data.email);
            localStorage.setItem('user_email', email.toString());
            var password = window.btoa(data.password.toString());
            localStorage.setItem('user_password', password);
            localStorage.setItem('isRemember', this.isRemember.toString());
        }
        else {
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_password');
            localStorage.removeItem('isRemember');
        }
    };
    LoginComponent.prototype.checkValue = function (value) {
        if (value) {
            this.isRemember = true;
        }
        else {
            this.isRemember = false;
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
