import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, GeolocationService } from '../services';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
})
export class AddClientComponent implements OnInit {
  @Input() editClientInfo;
  isEdit = false;
  clientForm: FormGroup;
  isSubmitted = false;
  loading = false;
  phoneCode: any = [];
  colorCodes: any = [
    { id: 1, color: '#e74c3c' },
    { id: 2, color: '#e67e22' },
    { id: 3, color: '#f39c12' },
    { id: 4, color: '#34495e' },
    { id: 5, color: '#8e44ad' },
    { id: 6, color: '#3498db' },
    { id: 7, color: '#27ae60' },
    { id: 8, color: '#16a085' },
  ];
  defaultCountryCode: any = 'NZ';
  mcountryCode;
  mnzcode;
  mcountryFlag;
  pcountryCode;
  pnzcode;
  pcountryFlag;
  isMobile = false;
  isPhone = false;
  isEmail = false;

  constructor(
    private geolocationService: GeolocationService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.createForm();
    this.getCurrentLocation();
    this.getPhoneCode();
    if (this.editClientInfo && this.editClientInfo['id']) {
      this.setEditForm(this.editClientInfo);
    }
  }

  async getCurrentLocation() {
    this.defaultCountryCode = await this.geolocationService.getCurrentCountry();
    this.setcountryFlag();
  }

  setcountrycodeflag(intphoneData, type) {
    if (type === 'phone' || type === 'new') {
      this.pcountryCode = intphoneData.countries_isd_code;
      this.pcountryFlag = intphoneData.countries_iso_code;
      this.pnzcode = intphoneData.id;
    }

    if (type === 'mobile' || type === 'new') {
      this.mcountryCode = intphoneData.countries_isd_code;
      this.mcountryFlag = intphoneData.countries_iso_code;
      this.mnzcode = intphoneData.id;
    }

    this.clientForm.patchValue({
      phone_code: this.pnzcode,
      mobile_code: this.mnzcode,
    });
  }

  setcountryFlag() {
    const intphoneData = this.phoneCode.filter((obj) => obj.countries_iso_code === this.defaultCountryCode);
    if (!this.editClientInfo) {
      if (intphoneData && intphoneData.length > 0) {
        this.setcountrycodeflag(intphoneData[0], 'new');
      }
    } else {
      if (this.editClientInfo) {
        const mobileData = this.phoneCode.filter((obj) => obj.id === this.editClientInfo.mobile_id);
        const phoneData = this.phoneCode.filter((obj) => obj.id === this.editClientInfo.phone_id);
        if (phoneData && phoneData.length > 0) {
          this.setcountrycodeflag(phoneData[0], 'phone');
        } else {
          this.setcountrycodeflag(intphoneData[0], 'phone');
        }

        if (mobileData && mobileData.length > 0) {
          this.setcountrycodeflag(mobileData[0], 'mobile');
        } else {
          this.setcountrycodeflag(intphoneData[0], 'mobile');
        }
      }
    }
  }

  getPhoneCode() {
    this.apiService.get('phone-code').subscribe((phoneCode: any) => {
      if (phoneCode.status === 200) {
        this.phoneCode = phoneCode['phonecodes'];
        if (this.phoneCode && this.phoneCode.length > 0) {
          this.setcountryFlag();
        }
      }
    });
  }

  createForm() {
    this.clientForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      name: ['', Validators.required],
      mobile: [''],
      contact_person: [''],
      phone: [''],
      is_newsletter_subscribed: [0],
      background_color: ['#e74c3c'],
      phone_code: [this.pnzcode],
      mobile_code: [this.mnzcode, Validators.required],
    });
  }

  get f() {
    return this.clientForm.controls;
  }

  setEditForm(data) {
    this.isEdit = true;
    this.clientForm.patchValue({
      email: data.email,
      name: data.name,
      contact_person: data.contact_person,
      mobile: data.mobile,
      mobile_code: data.mobile_id ? data.mobile_id : this.mnzcode,
      phone: data.phone,
      phone_code: data.phone_id ? data.phone_id : this.pnzcode,
      is_newsletter_subscribed: data.is_newsletter_subscribed,
      background_color: data.background_color,
    });
  }

  setCountryInfo(code, type) {
    this.setcountrycodeflag(code, type);
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  isNumberKey(evt, value) {
    //if (evt != 43 && evt > 31 && (evt < 48 || evt > 57)){
    if (evt > 31 && (evt < 48 || evt > 57)) {
      return false;
    } else if (value.length > 9) {
      return false;
    }
    return true;
  }

  checkError(err) {
    if (err.error.message === 'Mobile Number Already Registered') {
      this.isMobile = true;
    }
    if (err.error.message === 'Phone Number Already Registered') {
      this.isPhone = true;
    }
    if (err.error.message === 'Email Id already exists') {
      this.isEmail = true;
    }
  }

  createClient() {
    this.isSubmitted = true;
    if (this.clientForm.invalid) {
      return;
    }
    const datavalue = this.clientForm.value;

    if (
      datavalue['phone'] &&
      datavalue['phone'].toString() === datavalue['mobile'] &&
      datavalue['mobile'].toString() &&
      datavalue['phone_code'] === datavalue['mobile_code']
    ) {
      this.isPhone = true;
      this.toastr.error('Mobile and Phone are Same');
      return;
    }
    datavalue['is_newsletter_subscribed'] = datavalue['is_newsletter_subscribed'] ? 1 : 0;
    if (datavalue['phone'] === '' || datavalue['phone'] === null) datavalue['phone_code'] = '';
    this.loading = true;
    if (this.isEdit) {
      if (this.editClientInfo && this.editClientInfo['userLogId'])
        datavalue['userLogId'] = this.editClientInfo['userLogId'];

      this.apiService.put('client/update/' + this.editClientInfo['id'], datavalue).subscribe(
        (client: any) => {
          if (client.status === 200) {
            this.apiService.closeClientModel.emit(7);
            this.loading = false;
            this.toastr.success(client.message);
          }
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    } else {
      this.apiService.post('client', datavalue).subscribe(
        (client: any) => {
          if (client.status === 200) {
            this.apiService.closeClientModel.emit(2);
            this.loading = false;
            this.router.navigate(['/client-details/' + client.client['id']]);
            this.toastr.success(client.message);
          }
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    }
  }

  errorMessage(err) {
    this.loading = false;
    if (err.error.message) {
      this.toastr.error(err.error.message);
      this.checkError(err);
    }
  }
  closeModel() {
    if (this.isEdit) {
      this.apiService.closeClientModel.emit(7);
    } else {
      this.apiService.closeClientModel.emit(1);
    }
    this.isEdit = false;
  }
}
