<div class="container">
  <div class="row align-items-center">
    <div class="col-xl-5 col-md-8 offset-md-2 col-lg-6 offset-lg-3 offset-xl-4 my-auto">
      <div class="d-flex align-items-center justify-content-center h-100vh">
        <div class="card auth-card col-md-11">
          <div class="card-header text-center text-white auth-header">
            <img src="assets/images/logo-w.png" alt="logo" class="logo" />
          </div>
          <div class="card-body bg-white">
            <h1 class="card-title text-dark text-left fs20">LOGIN</h1>
            <div class="border-primary w-25 login-bdr mb-3"></div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
              <div class="form-group bg-white">
                <label for="exampleInputEmail1" class="text-dark font-weight-bold fs14">Email</label>
                <input
                  type="email"
                  class="form-control bg-white fs14"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && email.errors }"
                  required
                />
                <div *ngIf="submitted && email.errors" class="invalid-feedback my-2">
                  <div *ngIf="email.errors.required">Please enter Email</div>
                  <div *ngIf="email.errors.email">Please enter valid address</div>
                </div>
              </div>
              <div class="form-group bg-white">
                <label for="exampleInputPassword1" class="text-dark font-weight-bold fs14">Password</label>
                <input
                  type="password"
                  class="form-control bg-white fs14"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && password.errors }"
                  required
                />
                <div *ngIf="submitted && password.errors" class="invalid-feedback my-2">
                  <div *ngIf="password.errors.required">Please enter Password</div>
                </div>
              </div>
              <div class="form-group form-check d-inline-flex">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  (change)="checkValue($event.target.checked)"
                  [checked]="isRemember"
                />
                <label class="form-check-label fs14" for="exampleCheck1" class="text-secondary mr-1">Remember me</label>
              </div>
              <div class="text-right float-right">
                <p class="text-primary c-pointer fs14" routerLink="/forgot-password">Forgot Password ?</p>
              </div>
              <button type="submit" class="btn btn-primary btn-block">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
